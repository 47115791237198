// Modal Info Panel
.modal-info {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, .5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1021;
  
  &__panel {
    background-color: #fff;
    box-shadow: 0px 0px 18px -3px rgba(0,0,0,0.6);
    max-width: 500px;
    padding: 10px;
    width: 90%;
  }

  &__inner {
    background-color: transparent;
    border: 1px solid #000;
    height: 100%;
    padding: 32px 28px 16px;
    width: 100%;

    @media screen and (max-width: 750px) {
      padding: 16px;
    }
  }

  &__head-wrap {
    display: flex;
    align-items: center;
  }

  &__icon {
    margin-right: 30px;
    width: 82px;

    @media screen and (max-width: 750px) {
      margin-right: 16px;
      width: 54px;
    }
  }

  &__head {
    flex-shrink: 1;
    font-size: 18px;
    font-weight: bold;

    @media screen and (max-width: 750px) {
      font-size: 15px;
    }
  }

  &__body {
    font-size: 14px;
    margin-top: 32px;
    font-weight: 600;
    line-height: 24px;

    @media screen and (max-width: 750px) {
      font-size: 13px;
      margin-top: 16px;
    }
  }

  &__button {
    background-color: #000;
    border: 1px solid transparent;
    cursor: pointer;
    color: #fff;
    display: block;
    font-size: 14px;
    height: 40px;
    margin: 32px auto 0;
    transition: .3s ease-in-out;
    width: 128px;

    @media screen and (max-width: 750px) {
      margin-top: 16px;
    }

    &:hover {
      background-color: #fff;
      color: #000;
      border: 1px solid #000;
    }

    &:focus {
      outline: none;
    }
  }
}